import {WIX_ECOM, WIX_NEW_STORES, MEMBERS_AREA} from '@wix/app-definition-ids';

export const STORES_APP_DEF_ID = WIX_NEW_STORES;
export const BLOCKS_PRODUCT_PAGE_APP_DEF_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087';
export const ECOM_APP_DEF_ID = WIX_ECOM;
export const APP_INSTALLED_ACTION = 'appInstalled';
export const MEMBERS_AREA_APP_DEF_ID = MEMBERS_AREA;
export const STUDIO_SUBTYPE = 'STUDIO';
export const STUDIO_BRAND = 'studio';

export enum FedopsInteractions {
  StoresInstallation = 'stores-installation',
  BlocksProductPageAppInstallation = 'blocks-product-page-app-installation',
  ProductAddOoiPage = 'ooi-product-page-add-page',
  CategoryPageAndRouterInstallationProcess = 'category-page-page-and-router-installation-process',
  CategoryAddPage = 'category-page-add-page',
  CategoryAddRouter = 'category-page-add-router',
  CategoryConnectRouter = 'category-page-connect-router',
  CategoryAddPageToSiteMenu = 'category-page-add-page-to-site-menu',
}
